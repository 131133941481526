import { CardDescription } from '@styles/styled';
import React from 'react';
import { Typography } from '@mui/material';
import DownloadButton from '@components/elements/DownloadButton';

type DownloadReadyProtocolT = {
  address: string;
  downloadProtocol: () => void;
};

const DownloadReadyProtocol = ({
  address,
  downloadProtocol,
}: DownloadReadyProtocolT) => (
  <>
    <CardDescription sx={{ fontWeight: 'bold' }}>
      PROTOKÓŁ GOTOWY DO POBRANIA
    </CardDescription>
    <Typography fontSize="14px">
      Przekazany protokół został zweryfikowany i podpisany przez pracownika
      urzędu. Poniżej mogą Państwo pobrać gotowy dokument protokołu.
    </Typography>
    <Typography fontSize="20px" fontWeight="bold" mt={2}>
      PROTOKÓŁ KONTROLI
    </Typography>
    <Typography fontSize="20px" fontWeight="bold" mt={1}>
      {address}
    </Typography>
    <DownloadButton
      sx={{ marginTop: 2 }}
      downloadFn={() => downloadProtocol()}
    />
  </>
);

export default DownloadReadyProtocol;
